<template>
  <SignInLayout>
    <template v-slot:right>
      <div class="login_page">
        <VerifyComplete/>
      </div>
      <SelectLang></SelectLang>
    </template>
  </SignInLayout>
</template>
<script>
import { Base64 } from 'js-base64'
import VerifyComplete from '@/components/SignUp/VerifyComplete.vue'
import SelectLang from '@/components/SelectLang.vue'
import SignInLayout from '@/layout/SignInLayout.vue'

export default {
  components: {
    SignInLayout,
    SelectLang,
    VerifyComplete

  },
  data () {
    return {
      //vid
      vid: null,
    }
  },
  created () {
    //取得當前query數量
    const queryCount = Object.keys(this.$route.query).length

    //判斷是否有傳遞query
    if (queryCount > 0) {
      this.vid = this.$route.query.vid
      const decodeStr = Base64.decode(this.vid)
        .replace('vid=', '')
        .replace('type=', '')
        .replace('uid=', '')
        .split('&')
      if (decodeStr.length === 3) {
        this.$v1Api.emailVerify({
          vid: decodeStr[0],
          type: decodeStr[1],
          uid: decodeStr[2],
        }).then(res => {
          if (res && !res.err) {
            //逾時重新發送email
            if (res.errno === 49) {
              this.$setLocalStorage('email', res.data.email)
              this.$router.push('/EmailLinkExpired')
            }
          }
        })
      } else {
        this.$router.push('/')
      }
    } else {
      this.$router.push('/')
    }
  }
}
</script>
