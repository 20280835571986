<template>
  <SignInLayout>
    <template v-slot:right>
      <div class="login_page">
        <div class="sign_in_email">
          <div class="backStep" @click="$router.go(-1)">
            <img src="../assets/images/backStep.svg" alt="">
            {{ $t('general.goBack') }}
          </div>
          <h2>{{ $t('selectLang.selectYourLang') }}</h2>
          <div class="lan">
            <a @click="changeLang('en-US')" href="">{{ $t('locale.en-US') }}</a>
            <a @click="changeLang('de-De')" href="">{{ $t('locale.de-De') }}</a>
            <a @click="changeLang('fr')" href="">{{ $t('locale.fr') }}</a>
            <a @click="changeLang('es')" href="">{{ $t('locale.es') }}</a>
            <a @click="changeLang('it')" href="">{{ $t('locale.it') }}</a>
            <a @click="changeLang('nl')" href="">{{ $t('locale.nl') }}</a>
            <a @click="changeLang('zh-TW')" href="">{{ $t('locale.zh-TW') }}</a>
            <a @click="changeLang('zh-CN')" href="">{{ $t('locale.zh-CN') }}</a>
            <a @click="changeLang('ja-JP')" href="">{{ $t('locale.ja-JP') }}</a>
            <a @click="changeLang('ko-KR')" href="">{{ $t('locale.ko-KR') }}</a>
            <a @click="changeLang('fi-FI')" href="">{{ $t('locale.fi-FI') }}</a>
            <a @click="changeLang('ru-RU')" href="">{{ $t('locale.ru-RU') }}</a>
            <a @click="changeLang('th-TH')" href="">{{ $t('locale.th-TH') }}</a>
          </div>
        </div>
      </div>
    </template>
  </SignInLayout>
</template>
<script>
import SignInLayout from '@/layout/SignInLayout.vue'
import {changeLocale} from 'ipevo_utils/dist'

export default {
  components: {SignInLayout},
  methods: {
    /**
     * 設定語言
     * @param lang lang
     */
    changeLang(lang) {
      changeLocale(lang)
      const params = this.$route.params
      if (!params) {
        this.$router.push('/')
      } else {
        //返回上一頁
        this.$router.push(params.url)
      }
    }
  }
}
</script>
<style scoped lang="scss">
h2 {
  margin-bottom: 30px;
}

.lan {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  width: 100%;

  a {
    display: block;
    text-align: left;
    font-size: 16px;
    margin-bottom: 20px;
    color: #646464;
    width: calc((100% - 16px) / 2);
  }
}
</style>
