<template>
  <div class="sign_in_email">
    <h2>{{ $t('emailState.linkExpired') }}</h2>
    <div class="no_account">
      <p>{{ $t('emailState.expiredDescription') }}</p>
    </div>

    <div class="sign_in_end single">
      <a class="continue" @click="resendEmail">{{ $t('emailState.resend') }}</a>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      email: null,
    }
  },
  created () {
    this.email = this.$getLocalStorage('email')
    if (!this.email) {
      this.$router.push('/')
    }
  },
  methods: {
    /**
     * 重送email
     */
    resendEmail () {
      this.$v1Api.sendEmailVerify({
        email: this.email,
        type: 0,
      }).then(res => {
        if (res && !res.err) {
          this.$lib.showAlertDialog(res.msg, null, 'success')
        }
      })
    }
  }
}
</script>
