<template>
  <SignInLayout>
    <template v-slot:right>
      <div class="login_page">
        <router-link to="/" class="back_to_signin">
          {{ $t('resetPassword.returnToSignIn') }}
        </router-link>
        <div class="sign_in_email">
          <h2 class="reset_password">{{ $t('resetPassword.resetPassword') }}</h2>
          <div class="no_account">
            <p>{{ $t('resetPassword.resetTip') }}</p>
          </div>
          <label for="email_address">{{ $t('sign.emailAddress') }}</label>
          <input v-model="email" type="email" ref="email" id="email_address" name="email_address"
                 @keydown.enter="resetPassword">
          <div class="sign_in_end single">
            <button class="continue" @click="resetPassword">{{ $t('sign.continue') }}</button>
          </div>
        </div>
      </div>
      <!--      <SelectLang></SelectLang>-->
    </template>
  </SignInLayout>
</template>
<script>
import SignInLayout from '@/layout/SignInLayout.vue'
import {DialogTypeEnum, showAlertDialog, validEmail} from "ipevo_utils/dist"

export default {
  components: {
    SignInLayout
  },
  data() {
    return {
      email: null,
    }
  },
  methods: {
    resetPassword() {
      if (!this.email || this.email.length === 0 || !validEmail(this.email)) {
        this.$refs.email.focus()
        showAlertDialog(this.$t('errors.validEmail'))
        return
      }

      this.$v1Api.forgetPassword({
        email: this.email
      }).then(res => {
        if (res && !res.err) {
          showAlertDialog(res.msg, null, DialogTypeEnum.SUCCESS)
        }
      })
    }
  }
}
</script>
<style scoped lang="scss">
.reset_password {
  margin-top: 60px;
}

.back_to_signin {
  position: absolute;
  top: 51px;
  left: 73px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  font-size: 16px;
  font-family: 'SimplonNorm-R', 'Noto Sans', sans-serif;
  color: #646464;

  &:before {
    content: '';
    display: block;
    width: 12.6px;
    height: 9.36px;
    margin-right: 13px;
    background-size: 12.6px;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url(../assets/images/arrow.svg);
  }
}
</style>
