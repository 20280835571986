<template>
  <header>
    <div class="logo">
      <router-link to="/profile">
        <img src="../assets/images/img_ipevoid.svg" alt="">
      </router-link>
    </div>
    <div class="accountState" v-if="this.isLogin && this.userInfo.name">
      <a class="accountName" href="javascript: void(0)">{{ `${userInfo.name.firstName} ${userInfo.name.lastName}` }}</a>
      <div class="userhover">
        <ul>
          <li>
            <img v-if="userInfo.cover_pic" :src="userInfo.cover_pic" alt="">
            <img v-else src="../assets/images/img_user-profile-image.svg" alt="">
            <div>
              <p class="userName">{{ `${userInfo.name.firstName} ${userInfo.name.lastName}` }}</p>
              <p class="userEmail">{{ userInfo.email }}</p>
            </div>
          </li>
          <li>
            <router-link to="/profile">
              {{ $t('userProfile.viewAccount') }}
            </router-link>
          </li>
          <li>
            <button class="logout" @click="logout">{{ $t('general.logOut') }}</button>
          </li>
        </ul>
      </div>
    </div>
    <div class="toggleNav" @click="toggle">
      <div v-bind:class="classObject"></div>
    </div>
  </header>
<!--  <nav v-bind:class="classObject">-->
<!--    <div class="navLink" v-if="this.isLogin">-->
<!--      <router-link to="/myLicense" :class="currentUrl===MenuEnum.MyLicense ? 'links active' : 'links'">-->
<!--        {{ $t('myLicense.nav.myLicense') }}-->
<!--      </router-link>-->

<!--      <router-link to="/download" :class="currentUrl===MenuEnum.Download ? 'links active' : 'links'">-->
<!--        {{ $t('myLicense.nav.downloads') }}-->
<!--      </router-link>-->

<!--      &lt;!&ndash;      <router-link to="" :class="currentUrl===MenuEnum.ManageTeam?'links active':'links'">&ndash;&gt;-->
<!--      &lt;!&ndash;        {{ $t('myLicense.nav.manageTeam') }}&ndash;&gt;-->
<!--      &lt;!&ndash;      </router-link>&ndash;&gt;-->

<!--      <router-link to="/userProfile" :class="currentUrl===MenuEnum.UserProfile ? 'links active' : 'links'">-->
<!--        {{ $t('myLicense.nav.account') }}-->
<!--      </router-link>-->

<!--      <router-link v-if="userInfo.hasLicenses" to="/referral"-->
<!--                   :class="currentUrl===MenuEnum.Referral ? 'links active':'links'">-->
<!--        {{ $t('myLicense.nav.referral') }}-->
<!--      </router-link>-->
<!--    </div>-->
<!--    <Footer></Footer>-->
<!--  </nav>-->

</template>
<script>
import Footer from '@/components/Footer.vue'

export default {
  components: {
    Footer
  },
  data () {
    return {
      //Menu enum
      MenuEnum: Object.freeze({
        //Download
        Download: '/download',
        //UserProfile
        UserProfile: '/userProfile',
        //ManageTeam
        ManageTeam: '',
        //My License
        MyLicense: '/myLicense',
        //Referral
        Referral: '/referral'
      }),
      //是否登入
      isLogin: false,
      //使用者資訊
      userInfo: {},
      //當前url
      currentUrl: null,
      //手機版nav 控制
      navToggle: false,
    }
  },
  computed: {
    classObject () {
      return {
        active: this.navToggle,
        inactive: !this.navToggle
      }
    }
  },
  methods: {
    /**
     * 登出
     */
    logout () {
      this.$v1Api.logout().then(res => {
        if (res && !res.err) {
          this.$remLocalStorage('token')
          this.$remLocalStorage('userInfo')
          this.$router.push('/')
        }
      })
    },
    /**
     * 取得個人檔案資訊
     */
    async getProfileInfo () {
      this.isLogin = !!this.$getLocalStorage('token')
      if (this.isLogin) {
        const res = await this.$v1Api.getProfileInfo({})

        if (res && !res.err) {
          this.userInfo = res.data
          this.$setLocalStorage('userInfo', JSON.stringify(this.userInfo))
        }
      }
    },
    toggle () {
      this.navToggle = !this.navToggle
    }
  },
  async created () {
    await this.getProfileInfo()
    this.currentUrl = this.$router.currentRoute.value.path
  }
}
</script>
