<template>
  <div class="home">
<!--    <router-link to="/myLicense">-->
      <div class="logo"></div>
<!--    </router-link>-->

    <div class="login">
      <div class="login_page">
        <div class="sign_in_email verify">
          <h2>{{ $t('userProfile.emailChange.update') }}</h2>
          <div class="user">
            <img class="userInfoImg" v-if="userInfo.cover_pic"
                 :src="userInfo.cover_pic" alt="">
            <img v-else src="../../assets/images/img_user-profile-image.svg" alt="">
            <div>
              <h3 id="userName">{{ `${userInfo.name.firstName} ${userInfo.name.lastName}` }}</h3>
              <p id="userEmail">{{ userInfo.email }}</p>
            </div>
          </div>
          <label for="newEmail">{{ $t('userProfile.emailChange.new') }}</label>
          <input type="text" id="newEmail" name="newEmail" v-model="email" @keydown.enter="modifyEmail">
          <div class="sign_in_end">
            <a class="continue cancel" @click="cancelEmail">{{ $t('general.cancel') }}</a>
            <a class="continue" @click="modifyEmail">{{ $t('userProfile.emailChange.update') }}</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {showAlertDialog, validEmail, validSameEmail} from "ipevo_utils/dist";

export default {
  props: {
    //使用者資訊
    userInfo: {},
  },
  data () {
    return {
      //email
      email: null,
    }
  },
  methods: {
    /**
     * 重送email
     */
    sendResetEmail () {
      this.$v1Api.sendResetEmail({
        email: this.email
      }).then(res => {
        if (res && !res.err) {
          this.$emit('nextStep', 3, this.email)
        }
      })
    },
    /**
     * 修改email
     */
    modifyEmail () {
      //判斷email格式是否相符或者為空
      if (!validEmail(this.email)) {
        showAlertDialog(this.$t('errors.validEmail'))
        return
      }

      //如果email相同直接攔截
      if (validSameEmail(this.email)) {
        showAlertDialog(this.$t('errors.validSameEmail'))
        return
      }

      this.sendResetEmail()
    },
    /**
     * 取消email
     */
    cancelEmail () {
      this.$router.push('/profile')
    },
  },
}
</script>
