<template>
  <div class="home">
<!--    <router-link to="/myLicense">-->
      <div class="logo"></div>
<!--    </router-link>-->
    <div class="login">
      <div class="login_page">
        <div class="sign_in_email verify">
          <h2>{{ $t('resetPassword.updateYourPassword') }}</h2>
          <div class="user">
            <img class="userInfoImg" v-if="userInfo.cover_pic"
                 :src="userInfo.cover_pic" alt="">
            <img v-else src="../../assets/images/img_user-profile-image.svg" alt="">
            <div>
              <h3 id="userName">{{ `${userInfo.name.firstName} ${userInfo.name.lastName}` }}</h3>
              <p id="userEmail">{{ userInfo.email }}</p>
            </div>
          </div>
          <div class="passwordFlex">
            <label for="newPassword">{{ $t('resetPassword.newPassword') }}</label>
            <input :type="showPassword===false?'password':'text'" id="newPassword" name="newPassword" class="password"
                   ref="password"
                   v-model="password">
            <button @click="showPassword=!showPassword"
                    :class="showPassword===false?'passwordSH':'passwordSH active'"></button>
          </div>
          <div class="passwordFlex">
            <label for="confirmPassword">{{ $t('resetPassword.verifyPassword') }}</label>
            <input :type="showConfirmPassword===false?'password':'text'" id="confirmPassword" name="confirmPassword" @keydown.enter="updatePassword"
                   class="password" ref="confirmPassword"
                   v-model="confirmPassword">
            <button @click="showConfirmPassword=!showConfirmPassword"
                    :class="showConfirmPassword===false?'passwordSH':'passwordSH active'"></button>
          </div>
          <div class="sign_in_end">
            <a class="continue cancel" @click="cancelPassword">{{ $t('general.cancel') }}</a>
            <a class="continue" @click="updatePassword">{{ $t('resetPassword.updatePassword') }}</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// import SelectLang from '@/components/SelectLang'
import {showAlertDialog, validPassword} from "ipevo_utils/dist";

export default {
  // components: { SelectLang },
  props: {
    //使用者資訊
    userInfo: {},
  },
  data () {
    return {
      //顯示密碼
      showPassword: false,
      //顯示核對密碼
      showConfirmPassword: false,
      //密碼
      password: null,
      //檢查密碼
      confirmPassword: null,
    }
  },
  methods: {
    /**
     * 更改是否顯示密碼
     */
    changeShowPassword () {
      this.showPassword = !this.showPassword
    },
    /**
     * 更新密碼
     */
    updatePassword () {
      //驗證密碼是否相符
      if (!validPassword(this.password)) {
        showAlertDialog(this.$t('errors.validPassword'))
        this.$refs.password.focus()
        return
      }

      if (!validPassword(this.confirmPassword)) {
        showAlertDialog(this.$t('errors.validPassword'))
        this.$refs.confirmPassword.focus()
        return
      }

      if (this.password !== this.confirmPassword) {
        showAlertDialog(this.$t('errors.validPasswordSame'))
        this.$refs.password.focus()
        return
      }

      this.$v1Api.changePassword({
        password: this.password,
      }).then(res => {
        if (res && !res.err) {
          showAlertDialog(res.msg, null, 'success')
          this.$router.push('/profile')
        }
      })
    },
    /**
     * 取消更新密碼
     */
    cancelPassword () {
      this.$router.push('/profile')
    },
  },
}
</script>
