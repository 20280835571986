<template>
  <SignInLayout>
    <template v-slot:right>
      <div class="login_page">
        <SignUpVerifyEmail :email="email"/>
      </div>
      <SelectLang></SelectLang>
    </template>
  </SignInLayout>
</template>

<script>
import SignUpVerifyEmail from "@/components/SignUp/SignUpVerifyEmail.vue";
import SelectLang from '@/components/SelectLang.vue'
import SignInLayout from '@/layout/SignInLayout.vue'

export default {
  components: {
    SignInLayout,
    SelectLang,
    SignUpVerifyEmail
  },
  data () {
    return {
      email: null
    }
  },
  created () {
    this.email = this.$getLocalStorage('email')
    if (!this.email) {
      this.$router.push('/')
    }
  }
}
</script>
