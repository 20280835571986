<template>
  <div class="home">
<!--    <router-link to="/myLicense">-->
      <div class="logo"></div>
<!--    </router-link>-->
    <div class="login">
      <div class="login_page">
        <div class="sign_in_email verify">
          <h2>{{ $t('resetPassword.verifyYourIdentity') }}</h2>
          <div class="user">
            <img class="userInfoImg" v-if="userInfo.cover_pic"
                 :src="userInfo.cover_pic" alt="">
            <img v-else src="../../assets/images/img_user-profile-image.svg" alt="">
            <div>
              <h3 id="userName">{{ `${userInfo.name.firstName} ${userInfo.name.lastName}` }}</h3>
              <p id="userEmail">{{ userInfo.email }}</p>
            </div>
          </div>
          <div class="passwordFlex">
            <label for="currentPassword">{{ $t('resetPassword.currentPassword') }}</label>
            <input :type="showPassword===false?'password':'text'" id="currentPassword" name="currentPassword"
                   @keydown.enter="verifyPassword"
                   class="password" ref="password" v-model="password">
            <button @click="showPassword=!showPassword"
                    :class="showPassword===false?'passwordSH':'passwordSH active'"></button>
          </div>
          <div class="sign_in_end single">
            <a class="continue" @click="verifyPassword">{{ $t('resetPassword.verify') }}</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {showAlertDialog, validPassword} from "ipevo_utils/dist";

export default {
  props: {
    //使用者資訊
    userInfo: {},
  },
  data () {
    return {
      //顯示密碼
      showPassword: false,
      //密碼
      password: null,
    }
  },
  methods: {
    /**
     * 驗證密碼
     */
    verifyPassword () {
      //驗證密碼
      if (!validPassword(this.password)) {
        showAlertDialog(this.$t('errors.validPassword'))
        return
      }

      this.$v1Api.verifyPassword({
        password: this.password
      }).then(res => {
        if (res && !res.err) {
          this.$emit('nextStep', 2)
        }
      })
    },
  },
}
</script>
