<template>
  <router-link class="select_lan" :to="{name:'selectLang', params: {url: this.$router.currentRoute.value.path}}">
    {{ $t(`locale.${lang}`) }}
  </router-link>
</template>

<script>
export default {
  data () {
    return {
      //語言
      lang: null,
    }
  },
  beforeMount () {
    //設定語言
    this.lang = this.$getLocalStorage('lang') ?? 'en-US'
  }
}
</script>
<style lang="scss">
footer {
  //position: relative;
  ////bottom: 0;
  ////left: 0;
  //width: 100%;
  //height: 160px;
  //padding: 0 140px;
  //background-color: #fff;
  //display: flex;
  //align-items: center;
  //justify-content: center;

  .select_lan {
    position: absolute;
    left: 140px;
    top: 50%;
    transform: translateY(-50%);
    width: initial;
    margin: 0;
    color: #646464;
    cursor: pointer;

    &:before {
      background-image: url(../assets/images/earth_dark.svg);
    }

    &:after {
      background-image: url(../assets/images/miniarrow_dark.svg);
    }
  }
}

.select_lan {
  width: 100%;
  padding-right: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  font-size: 12px;
  color: #fff;
  margin-top: 20px;
  cursor: pointer;

  &:before {
    content: '';
    display: block;
    width: 11.4px;
    height: 11.4px;
    background-size: 11.4px;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url(../assets/images/earth.svg);
    margin-right: 3.6px;
  }

  &:after {
    content: '';
    display: block;
    width: 8px;
    height: 4px;
    background-size: 8px;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url(../assets/images/miniarrow.svg);
    margin-left: 10px;
  }
}
</style>
