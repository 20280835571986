<script setup>
import SignInLayout from '@/layout/SignInLayout.vue'
</script>
<template>
  <SignInLayout>
    <template v-slot:right>
      <div class="login_page">
        <div class="sign_in_email verify">
          <h2>{{ $t('userProfile.deleteAccount.pleaseInputPasswordAgain') }}</h2>
          <div class="user">
            <img class="userInfoImg" v-if="userInfo.cover_pic"
                 :src="userInfo.cover_pic" alt="">
            <img v-else src="../../assets/images/img_user-profile-image.svg" alt="">
            <div>
<!--              <h3 id="userName">{{ `${userInfo.name.firstName} ${userInfo.name.lastName}` }}</h3>-->
              <p id="userEmail">{{ userInfo.email }}</p>
            </div>
          </div>
          <div class="passwordFlex">
            <label for="newPassword">{{ $t('userProfile.deleteAccount.password') }}</label>
            <input :type="showPassword===false?'password':'text'" id="newPassword" name="newPassword" class="password"
                   v-model="password">
            <button @click="showPassword=!showPassword"
                    :class="showPassword===false?'passwordSH':'passwordSH active'"></button>
          </div>
          <div class="sign_in_end">
            <a class="continue cancel" @click="cancelDelete">{{ $t('general.cancel') }}</a>
            <a class="continue warning" @click="verifyPassword">{{ $t('userProfile.deleteAccount.delete') }}</a>
          </div>
        </div>
      </div>
      <SelectLang></SelectLang>
    </template>
  </SignInLayout>
</template>
<script>
import SelectLang from '@/components/SelectLang.vue'
import {showAlertDialog, validPassword} from "ipevo_utils/dist";

export default {
  components: {
    SelectLang
  },
  props: {
    //使用者資訊
    userInfo: {},
  },
  data() {
    return {
      //顯示密碼
      showPassword: false,
      //密碼
      password: null,
    }
  },
  methods: {
    /**
     * 驗證密碼
     */
    verifyPassword() {
      //驗證密碼
      if (!validPassword(this.password)) {
        showAlertDialog(this.$t('errors.validPassword'))
        return
      }

      this.$v1Api.verifyPassword({
        password: this.password
      }).then(async res => {
        if (res && !res.err) {
          const result = await this.$v1Api.delUser({})
          if (result && !result.err) {
            this.$remLocalStorage('userInfo')
            this.$remLocalStorage('token')
            showAlertDialog(result.msg, null, 'success')
            this.$router.push('/')
          }
        }
      })
    },
    /**
     * 更改是否顯示密碼
     */
    changeShowPassword() {
      this.showPassword = !this.showPassword
    },
    cancelDelete() {
      this.$router.push('/profile')
    }
  }
}
</script>
