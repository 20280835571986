<template>
  <div>
  </div>
</template>

<script>
export default {
  created() {
    const query = this.$route.query
    //判斷看query是否有大於0
    if (Object.keys(query).length > 0) {
      const token = query.token
      const lang = query.lang

      if (lang) {
        this.$setLocalStorage('lang', lang)
      } else {
        this.$setLocalStorage('lang', 'en-US')
      }

      if (token) {
        this.$setLocalStorage('token', token.trim())
        this.$router.push('/profile')
      }
    }
  }
}
</script>

<style scoped>

</style>
