<template>
  <SignInLayout>
    <template v-slot:right>
      <div class="login_page">
        <SignInEmail @sendEmail="nextStep" v-if="index===0"/>
        <SignInPassword @backStep="backStep" :email="email" v-if="index===1"/>
      </div>
      <SelectLang></SelectLang>
    </template>
  </SignInLayout>
</template>

<script>
import SignInEmail from '@/components/SignIn/SignInEmail.vue'
import SignInPassword from '@/components/SignIn/SignInPassword.vue'
import SignInLayout from '@/layout/SignInLayout.vue'
import SelectLang from "@/components/SelectLang.vue";

export default {
  components: {
    SignInLayout,
    SelectLang,
    SignInEmail,
    SignInPassword,
  },
  data() {
    return {
      //email
      email: null,
      //index
      index: 0,
      //event check
      eventCheck: 0
    }
  },
  methods: {
    /**
     * 返回上一步驟
     * @param v {number} index
     */
    backStep(v) {
      this.index = v
    },
    /**
     * 子傳父
     * @param index index
     * @param email email
     */
    nextStep(index, email) {
      this.index = index
      this.email = email
    },
  },
}
</script>
