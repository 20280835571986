<template>
  <Step1 :userInfo="userInfo" @nextStep="nextStep" v-if="step===1"></Step1>
  <Step2 :userInfo="userInfo" @nextStep="nextStep" v-else-if="step===2"></Step2>
  <Step3 :userInfo="userInfo" v-else></Step3>
</template>
<script>
import Step1 from '@/components/UserProfile/EmailVerify.vue'
import Step2 from '@/components/UserProfile/ChangeEmail.vue'
import Step3 from '@/components/UserProfile/ResetEmail.vue'

export default {
  components: {
    Step1,
    Step2,
    Step3
  },
  data () {
    return {
      //步驟
      step: 1,
      //使用者資訊
      userInfo: {},
    }
  },
  methods: {
    /**
     * 下一步
     * @param v {number} index
     * @param n {string} new email
     * @return void
     */
    nextStep (v, n) {
      this.step = v
      if (n) {
        this.userInfo.newEmail = n
      }
    },
    /**
     * 取得用戶資訊
     */
    getUserInfo () {
      const userInfo = this.$getLocalStorage('userInfo')
      if (!userInfo) {
        this.$v1Api.getProfileInfo({}).then(res => {
          this.userInfo = res.data
          this.$setLocalStorage('userInfo')
        })
      } else {
        this.userInfo = JSON.parse(userInfo)
      }
    }
  },
  created () {
    this.getUserInfo()
  }
}
</script>
