<template>
  <SignInLayout>
    <template v-slot:right>
      <div class="login_page">
        <EmailLinkExpired />
      </div>
      <SelectLang></SelectLang>
    </template>
  </SignInLayout>
</template>

<script>
import EmailLinkExpired from "@/components/SignUp/EmailLinkExpired.vue";
import SelectLang from '@/components/SelectLang.vue'
import SignInLayout from '@/layout/SignInLayout.vue'

export default {
  components: {
    SignInLayout,
    SelectLang,
    EmailLinkExpired
  },
}
</script>
