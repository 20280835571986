<template>
  <div class="home">
<!--    <router-link to="/myLicense">-->
      <div class="logo"></div>
<!--    </router-link>-->
    <div class="login">
      <div class="login_page">
        <div class="backStep" @click="$router.go(-1)">
          <img src="../../assets/images/backStep.svg" alt="">
          {{ $t('general.goBack') }}
        </div>
        <div class="sign_in_email verify">
          <h2>{{ $t('userProfile.email.resetEmailVerify') }}</h2>
          <div class="no_account verifyEmail">
            <p>{{ $t('sign.sentEmail') }}<br><br>
              <span>{{ userInfo.newEmail }}</span><br><br>
<!--              {{ $t('userProfile.email.clickNewEmailVerify') }}<br>-->
              <span @click="sendEmailVerify">{{ $t('sign.noReceive') }}</span>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {showAlertDialog} from "ipevo_utils/dist";

export default {
  props: {
    //使用者資訊
    userInfo: {},
  },
  methods: {
    /**
     * 重新發送email驗證
     */
    sendEmailVerify () {
      this.$v1Api.sendEmailVerify({
        email: this.userInfo.email,
        type: 1,
      }).then(res => {
        if (res && !res.err) {
          showAlertDialog(res.msg, null, 'success')
        }
      })
    },
  },
}
</script>
