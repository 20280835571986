<template>
  <Step1 :userInfo="userInfo" @nextStep="nextStep" v-if="step===1"></Step1>
  <Step2 :userInfo="userInfo" v-else></Step2>
</template>
<script>
import Step1 from '@/components/UserProfile/PasswordVerify.vue'
import Step2 from '@/components/UserProfile/ChangePassword.vue'

export default {
  components: {
    Step1,
    Step2
  },
  data () {
    return {
      //步驟
      step: 1,
      //使用者資訊
      userInfo: {},
    }
  },
  methods: {
    //下一步
    nextStep (v) {
      this.step = v
    },
    /**
     * 取得用戶資訊
     */
    getUserInfo () {
      const userInfo = this.$getLocalStorage('userInfo')
      if (!userInfo) {
        this.$v1Api.getProfileInfo({}).then(res => {
          this.userInfo = res.data
          this.$setLocalStorage('userInfo')
        })
      } else {
        this.userInfo = JSON.parse(userInfo)
      }
    }
  },
  created () {
    this.getUserInfo()
  }
}
</script>
