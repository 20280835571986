<template>
  <div class="sign_in_email">
    <h2>{{ $t('sign.verifyEmail') }}</h2>
    <div class="no_account verifyEmail">
      <p>
        {{ $t('sign.sentEmail') }}
        <br><br>
        <span>{{ email }}</span>
        <br><br>
        {{ $t('sign.clickVerify') }} <br>
        <span @click="resendEmail">{{ $t('sign.noReceive') }}</span>
      </p>
    </div>
    <span>{{ $t('sign.completeAndReLogin') }}</span>
    <a href="https://www.ipevo.com/choose-region" class="text-[#74BA00]"><span>{{ $t('general.ipevoWebsite') }}</span></a>
  </div>
</template>

<script>
import { showAlertDialog } from "ipevo_utils/dist";

export default {
  props: {
    email: {
      type: String,
      default: null,
    }
  },
  methods: {
    /**
     * 重送email
     */
    resendEmail () {
      this.$v1Api.sendEmailVerify({
        email: this.email,
        type: 0,
      }).then(res => {
        if (res && !res.err) {
          showAlertDialog(res.msg, null, 'success')
        }
      })
    }
  }
}
</script>
