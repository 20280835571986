<template>
  <div class="app">
    <notifications position="top center" width="60%"/>
    <router-view ref="view" @setLoadingValue="setLoadingValue"/>
    <Loading :loading="loading"></Loading>
  </div>
</template>
<script>
import Loading from "@/components/Loading.vue"
import {Notifications} from "@kyvg/vue3-notification"

export default {
  components: {Notifications, Loading},
  data() {
    return {
      //是否載入
      loading: false,
    }
  },
  mounted() {
    window.$view = this.$refs.view
    window.document.title = this.$t('title')
  },
  methods: {
    /**
     * 設定顯示載入視窗
     * @param v {boolean}
     */
    setLoadingValue(v) {
      this.loading = v
    }
  }
}
</script>

<style lang="scss">
.app, #app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  //text-align: center;
  color: #2c3e50;
  width: 100%;
  height: 100%;
}

.lds-ring {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 1);
  position: fixed;
  top: 0;
  left: 0;
}

.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid #fff;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #fff transparent transparent transparent;
}

.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}

.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}

.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}

@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>

