<template>
  <footer>
    <router-link class="select_lan"
                 :to="{name:'selectLang', params: {url: this.$router.currentRoute.value.path}}">
      {{ $t(`locale.${lang}`) }}
    </router-link>
    <div class="footerLink">
      <a href="https://www.ipevo.com/about-ipevo" target="_blank" class="links">{{ $t('general.about') }}</a>
      <a href="https://www.ipevo.com/privacy-statement" target="_blank" class="links">{{
          $t('general.privacyPolicy')
        }}</a>
      <a href="https://www.ipevo.com/terms-of-use" target="_blank" class="links">{{ $t('general.termsAndService') }}</a>
      <a class="links" href="https://www.ipevo.com/help-center/request" target="_blank">
        {{ $t('general.contactUs') }}
      </a>
    </div>
    <div class="copyright">
      <p>{{ $t('general.copyright', {year: new Date().getFullYear()}) }}</p>
    </div>
  </footer>
</template>
<script>
export default {
  data() {
    return {
      //語言
      lang: null,
    }
  },
  mounted() {
    //設定語言
    this.lang = this.$getLocalStorage('lang') ?? 'en-US'
  }
}
</script>
