<template>
  <div class="sign_in_email">
    <h2>{{ $t('sign.verifyComplete') }}</h2>
    <div class="no_account">
      <p>{{ $t('sign.reSignIn.paragraphOne') }}<br>
        {{ $t('sign.reSignIn.paragraphTwo') }}</p>
    </div>
    <div class="sign_in_end single">
      <a class="continue" href="javascript:void(0)" @click="$router.push('/')">{{ $t('sign.signIn') }}</a>
    </div>
  </div>
</template>
