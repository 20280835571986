<template>
  <DeleteAccount :userInfo="userInfo"></DeleteAccount>
</template>

<script>
import DeleteAccount from '@/components/UserProfile/DeleteAccount.vue'

export default {
  components: {
    DeleteAccount
  },
  data () {
    return {
      //使用者資訊
      userInfo: {},
    }
  },
  // methods: {
  //   getUserInfo () {
  //     const userInfo = this.$getLocalStorage('userInfo')
  //     if (!userInfo) {
  //       this.$v1Api.getProfileInfo({}).then(res => {
  //         this.userInfo = res.data
  //         this.$setLocalStorage('userInfo')
  //       })
  //     } else {
  //       this.userInfo = JSON.parse(userInfo)
  //     }
  //   },
  // },
  // created () {
  //   this.getUserInfo()
  // }
}
</script>
