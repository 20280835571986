<template>
  <div class="accountInfo">
    <NavBar/>
    <div class="userInfo">
      <div class="infoSidebar">
        <div class="picture">
          <img v-if="userInfo.cover_pic" :src="userInfo.cover_pic" alt="">
          <img v-else src="../../assets/images/img_user-profile-image.svg" alt="">
          <label for="uploadImg">{{ $t('userProfile.edit') }}</label>
          <input type="file" id="uploadImg" accept="image/png, image/gif, image/jpeg, image/jpg" @change="uploadCover"
                 ref="coverFile">
        </div>
        <div class="userName">
          <h2>{{ `${userInfo.name.firstName} ${userInfo.name.lastName}` }}</h2>
        </div>
        <div class="userAccount">
          <p>{{ userInfo.email }}</p>
        </div>
        <div class="containSwitch">
          <ul>
            <li @click="changePersonalInfo(true)" :class="{active:isPersonalInfo}">
              {{ $t('userProfile.personalInfo') }}
            </li>
            <!--            License Portal-->
            <li>
              <a :href="getProductLicenseUrl()" target="_blank">{{ $t('userProfile.productLicense') }}</a>
            </li>
            <li @click="changePersonalInfo(false)" :class="{active:!isPersonalInfo}">
              {{ $t('sign.security.signInSecurity') }}
            </li>
            <li>
              <a href="https://www.ipevo.com/choose-region">{{ $t('general.ipevoWebsite') }}</a>
            </li>
          </ul>
        </div>
      </div>
      <div class="infoContain">
        <div class="personalInfo">
          <h2 v-if="isPersonalInfo">{{ $t('userProfile.personalInfo') }}</h2>
          <h2 v-else>{{ $t('sign.security.signInSecurity') }}
            <br>
            <span>{{ $t('userProfile.manage') }}</span>
          </h2>
          <div v-if="isPersonalInfo" class="infoDetail">
            <ul>
              <!--      姓名        -->
              <li>
                <p>{{ $t('userProfile.name.name') }}</p>
                <p class="userName">{{ `${userInfo.name.firstName} ${userInfo.name.lastName}` }}</p>
                <a href="javascript:void(0)" @click="showModifyDialog(ChangeEnum.UserNameChange)" class="edit"></a>
              </li>
              <!--      性別        -->
              <li>
                <p>{{ $t('userProfile.gender.gender') }}</p>
                <p class="userGender" v-if="userInfo.gender===GenderEnum.NotSelected">
                  {{ $t('general.notSelected') }}
                </p>
                <p class="userGender" v-if="userInfo.gender===GenderEnum.Male">
                  {{ $t('userProfile.gender.male') }}
                </p>
                <p class="userGender" v-if="userInfo.gender===GenderEnum.Female">
                  {{ $t('userProfile.gender.female') }}
                </p>
                <p class="userGender" v-if="userInfo.gender===GenderEnum.RatherNotSay">
                  {{ $t('userProfile.gender.ratherNotSay') }}
                </p>
                <a href="javascript:void(0)" @click="showModifyDialog(ChangeEnum.UserGenderChange)" class="edit"></a>
              </li>
              <!--      生日        -->
              <li>
                <p>{{ $t('userProfile.birthday.birthday') }}</p>
                <p class="userBirth">
                  <span id="ubmonth">
                    {{ $t(`month.abbrList.${new Date(userInfo.birthday * 1000).getMonth() + 1}`) }}
                  </span>,
                  &nbsp;
                  <span id="ubdate">{{ new Date(userInfo.birthday * 1000).getDate() }}</span>, &nbsp;
                  <span id="ubYear">{{ new Date(userInfo.birthday * 1000).getFullYear() }}</span>
                </p>
                <a href="javascript:void(0)" @click="showModifyDialog(ChangeEnum.UserBirthChange)" class="edit"></a>
              </li>
              <!--      國家        -->
              <li>
                <p>{{ $t('userProfile.country.countryRegion') }}</p>
                <p class="userCountry" v-if="userInfo.country===0">{{ $t('general.notSelected') }}</p>
                <p class="userCountry" v-else>{{ country[userInfo.country - 1] }}</p>
                <a href="javascript:void(0)" @click="showModifyDialog(ChangeEnum.UserCountryChange)" class="edit"></a>
              </li>
              <!--      職業        -->
              <li>
                <p>{{ $t('userProfile.occupation.occupation') }}</p>
                <p class="userOccupation" v-if="userInfo.occupation===0">{{ $t('general.notSelected') }}</p>
                <p class="userOccupation" v-else>
                  {{ getOccupationStr() }}
                </p>
                <a href="javascript:void(0)" @click="showModifyDialog(ChangeEnum.UserOccupationChange)"
                   class="edit"></a>
              </li>
            </ul>
          </div>
          <!--          SignIn & Security-->
          <div v-else class="infoDetail signInSecurity">
            <ul>
              <!--      Email[ipevo ID]        -->
              <li>
                <p>Email (IPEVO ID)</p>
                <p class="userName">{{ userInfo.email }}</p>
<!--                <router-link class="edit" to="/emailVerify"></router-link>-->
              </li>
              <!--      password        -->
              <li>
                <p>{{ $t('sign.password.password') }}</p>
                <p class="userPassword">****************</p>
                <router-link class="edit" to="/passwordVerify"></router-link>
              </li>
            </ul>
            <!--      刪除表單      -->
            <div class="graybg deleteForm" v-if="showDeleteAccount === true">
              <div class="infoChange">
                <h2>{{ $t('userProfile.deleteAccount.permanently') }}</h2>
                <p>{{ $t('userProfile.deleteAccount.deleteContent') }}</p>
                <input type="checkbox" id="deleteConfirm" v-model="deleteAccountChecked">
                <label class="deleteConfirm" for="deleteConfirm">{{ $t('userProfile.deleteAccount.agreement') }}</label>
                <div class="editBtn">
                  <a :href="deleteAccountChecked ? '/deleteAccount' : '#'" class="save continue delete">
                    {{ $t('userProfile.deleteAccount.delete') }}
                  </a>
                  <a @click="showDeleteAccount = false" class="continue">
                    {{ $t('general.cancel') }}
                  </a>
                </div>
              </div>
            </div>
            <!--      刪除按鈕      -->
            <a :class="showDeleteAccount ? 'deleteAcc disable' : 'deleteAcc'"
               @click="()=>{showDeleteAccount = true;deleteAccountChecked = false}">
              {{ $t('userProfile.deleteAccount.delete') }}
            </a>
          </div>
          <!--     show dialog     -->
          <div class="changeUI" v-show="showDialog">
            <!--    name change-->
            <div class="graybg" v-if="showDialogIndex===ChangeEnum.UserNameChange">
              <div class="infoChange">
                <h2>{{ $t('userProfile.name.changeName') }}</h2>
                <label for="changeFirstName">{{ $t('userProfile.name.firstName') }}</label>
                <input type="text" name="changeFirstName" id="changeFirstName" v-model="form.firstName">
                <label for="changeLastName">{{ $t('userProfile.name.lastName') }}</label>
                <input type="text" id="changeLastName" name="changeLastName" v-model="form.lastName">
                <div class="editBtn">
                  <a href="javascript:void(0)" @click="closeModifyDialog" class="cancel continue">
                    {{ $t('general.cancel') }}
                  </a>
                  <a href="javascript:void(0)" @click="modifyUserName()"
                     class="save continue">
                    {{ $t('general.save') }}
                  </a>
                </div>
              </div>
            </div>
            <!--    gender change-->
            <div class="graybg" v-if="showDialogIndex===ChangeEnum.UserGenderChange">
              <div class="infoChange">
                <h2>{{ $t('userProfile.gender.changeGender') }}</h2>
                <label for="gender">{{ $t('userProfile.gender.gender') }}</label>
                <select name="gender" id="gender" v-model="form.gender">
                  <option :value="GenderEnum.NotSelected" v-if="userInfo.gender===0">
                    {{ $t('general.notSelected') }}
                  </option>
                  <option :value="GenderEnum.Male">{{ $t('userProfile.gender.male') }}</option>
                  <option :value="GenderEnum.Female">{{ $t('userProfile.gender.female') }}</option>
                  <option :value="GenderEnum.RatherNotSay">{{ $t('userProfile.gender.ratherNotSay') }}</option>
                </select>
                <div class="editBtn">
                  <a href="javascript:void(0)" class="cancel continue" @click="closeModifyDialog">
                    {{ $t('general.cancel') }}
                  </a>
                  <a href="javascript:void(0)" class="save continue" @click="modifyGender">
                    {{ $t('general.save') }}
                  </a>
                </div>
              </div>
            </div>
            <!--    birthday change-->
            <div class="graybg" v-if="showDialogIndex===ChangeEnum.UserBirthChange">
              <div class="infoChange">
                <h2>{{ $t('userProfile.birthday.changeBirthday') }}</h2>
                <div class="input_select">
                  <div class="year">
                    <label for="year">{{ $t('userProfile.birthday.year') }}</label>
                    <select name="year" id="year" v-model="form.year">
                      <option v-for="year in years" :key="year" :value="year">
                        {{ year }}
                      </option>
                    </select>
                  </div>
                  <div class="month">
                    <label for="month">{{ $t('userProfile.birthday.month') }}</label>
                    <select name="month" id="month" v-model="form.month" @change="updateDaysInMonth">
                      <option v-for="(month, index) in months" :key="index" :value="index">{{ month }}</option>
                    </select>
                  </div>
                  <div class="date">
                    <label for="date">{{ $t('userProfile.birthday.date') }}</label>
                    <select name="date" id="date" v-model="form.day">
                      <option v-for="date in dates" :key="date" :value="date">{{ date }}</option>
                    </select>
                  </div>

                </div>
                <div class="editBtn">
                  <a href="javascript:void(0)" class="cancel continue" @click="closeModifyDialog">
                    {{ $t('general.cancel') }}
                  </a>
                  <a href="javascript:void(0)" class="save continue" @click="modifyBirth">
                    {{ $t('general.save') }}
                  </a>
                </div>
              </div>
            </div>
            <!--    country&region change-->
            <div class="graybg" v-if="showDialogIndex===ChangeEnum.UserCountryChange">
              <div class="infoChange">
                <h2>{{ $t('userProfile.country.changeCountryRegion') }}</h2>
                <label for="country">{{ $t('userProfile.country.countryRegion') }}</label>
                <select name="country" id="country" v-model="form.country">
                  <option v-if="form.country===0" :value="GenderEnum.NotSelected">
                    {{ $t('general.notSelected') }}
                  </option>
                  <option v-for="c in country" :value="country.indexOf(c)+1" :key="country.indexOf(c)+1">
                    {{ c }}
                  </option>
                </select>
                <div class="editBtn">
                  <a href="javascript:void(0)" class="cancel continue" @click="closeModifyDialog">
                    {{ $t('general.cancel') }}
                  </a>
                  <a href="javascript:void(0)" class="save continue" @click="modifyCountry">
                    {{ $t('general.save') }}
                  </a>
                </div>
              </div>
            </div>
            <!--    occupation change-->
            <div class="graybg" v-if="showDialogIndex===ChangeEnum.UserOccupationChange">
              <div class="infoChange">
                <h2>{{ $t('userProfile.occupation.changeOccupation') }}</h2>
                <label for="occupation">{{ $t('userProfile.occupation.occupation') }}</label>
                <select name="occupation" id="occupation" v-model="form.occupation">
                  <option v-if="form.occupation===0" :value="GenderEnum.NotSelected">
                    {{ $t('general.notSelected') }}
                  </option>
                  <option v-for="o in occupationList" :value="o.id" :key="o.id">
                    {{ o.value }}
                  </option>
                </select>
                <div class="editBtn">
                  <a href="javascript:void(0)" class="cancel continue" @click="closeModifyDialog">
                    {{ $t('general.cancel') }}
                  </a>
                  <a href="javascript:void(0)" class="save continue" @click="modifyOccupation">
                    {{ $t('general.save') }}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <a href="javascript: void(0)" @click="logout" class="continue logout">{{ $t('general.logOut') }}</a>
    </div>
    <Footer/>
  </div>
</template>

<script>
import NavBar from '@/components/NavBar.vue'
import Footer from '@/components/Footer.vue'
import {
  showAlertDialog,
  validFileSize,
  validFileExtension,
  file2Base64,
  validString,
  validSelectNum,
  getFullYears,
  isDebugMode,
  UploadCoverEnum, getLocalStorage
} from "ipevo_utils/dist";

export default {
  components: {
    NavBar,
    Footer
  },
  watch: {
    'form.year'() {
      this.updateDaysInMonth()
    },
    'form.month'() {
      this.updateDaysInMonth()
    },
  },
  data() {
    return {
      //true=個人資料, false=登入與安全
      isPersonalInfo: true,
      //修改資料
      form: {},
      //職業
      occupation: null,
      //性別
      gender: null,
      //使用者資訊
      userInfo: {},
      //顯示哪個修改方匡
      showDialogIndex: 0,
      //顯示對話視窗
      showDialog: false,
      //性別列舉
      GenderEnum: Object.freeze({
        //尚未選擇
        NotSelected: 0,
        //男
        Male: 1,
        //女
        Female: 2,
        //不願意透露
        RatherNotSay: 3
      }),
      //for change enum
      ChangeEnum: Object.freeze({
        //UserBirthChange
        UserBirthChange: 1,
        //UserCountryChange
        UserCountryChange: 2,
        //UserGenderChange
        UserGenderChange: 3,
        //UserNameChange
        UserNameChange: 4,
        //UserOccupationChange
        UserOccupationChange: 5
      }),
      //年
      years: [],
      //月
      dates: [],
      //日
      months: [
        this.$t('month.january'),
        this.$t('month.february'),
        this.$t('month.march'),
        this.$t('month.april'),
        this.$t('month.may'),
        this.$t('month.june'),
        this.$t('month.july'),
        this.$t('month.august'),
        this.$t('month.september'),
        this.$t('month.october'),
        this.$t('month.november'),
        this.$t('month.december'),
      ],
      //國家
      country: [],
      //最大國家數量
      maxCountryLength: 254,
      //職業清單
      occupationList: [],
      //是否顯示刪除帳號表單
      showDeleteAccount: false,
      //是否勾選同意刪除帳號選項
      deleteAccountChecked: false,
      token: '',
    }
  },
  methods: {
    /**
     * 上傳Cover Image
     */
    async uploadCover() {
      const file = this.$refs.coverFile.files[0]
      //避免未選擇檔案
      if (file) {
        //篩選檔案副檔名是否相符
        const fileName = file.name
        const ext = fileName.substring(fileName.lastIndexOf('.') + 1).toLowerCase()
        if (!validFileExtension(ext, [UploadCoverEnum.GIF, UploadCoverEnum.PNG, UploadCoverEnum.JPG, UploadCoverEnum.JPEG])) {
          showAlertDialog(this.$t('errors.validFileUpload', {ext: [UploadCoverEnum.GIF, UploadCoverEnum.PNG, UploadCoverEnum.JPG, UploadCoverEnum.JPEG].join(' / ')}))
          this.$refs.coverFile.value = null
          return
        }

        //篩選檔案大小是否有超過
        if (!validFileSize(file)) {
          showAlertDialog(this.$t('errors.validFileSize', {size: 2}))
          this.$refs.coverFile.value = null
          return
        }

        const formData = new FormData()
        formData.append('file', file)
        this.$v1Api.modifyProfileImage(formData).then(res => {
          if (res && !res.err) {

            file2Base64(file).then(res => {
              this.userInfo.cover_pic = res
            })
          }
        })
      }
    },
    //   /**
    //    * 切換 個人資料 / 登入與安全
    //    */
    changePersonalInfo(v) {
      this.isPersonalInfo = v
    },
    /**
     * 關閉修改視窗
     */
    closeModifyDialog() {
      this.$setLocalStorage('userInfo', JSON.stringify(this.userInfo))
      this.showDialogIndex = 0
      this.showDialog = false
      //reset form
      this.form = {}
    },
    //   /**
    //    * 登出
    //    */
    logout() {
      this.$v1Api.logout().then(res => {
        if (res && !res.err) {
          this.$remLocalStorage('token')
          this.$remLocalStorage('userInfo')
          this.$router.push('/')
        }
      })
    },
    /**
     * 修改國家
     */
    modifyCountry() {
      //驗證國家
      if (!validSelectNum(this.form.country)) {
        showAlertDialog(this.$t('errors.validCountry'))
        return
      }

      this.$v1Api.modifyCountry({
        country: this.form.country,
      }).then(res => {
        if (res && !res.err) {
          showAlertDialog(res.msg, null, 'success')
          this.userInfo.country = this.form.country
          this.closeModifyDialog()
        }
      })
    },
    /**
     * 修改生日
     */
    modifyBirth() {
      //驗證年
      if (!this.form.year) {
        showAlertDialog(this.$t('errors.validYear'))
        return
      }

      //驗證月
      if (!this.form.month && this.form.month < 0) {
        showAlertDialog(this.$t('errors.validMonth'))
        return
      }

      //驗證日
      if (!this.form.day) {
        showAlertDialog(this.$t('errors.validDate'))
        return
      }

      //取得生日unix time
      const time = new Date(this.form.year, this.form.month, this.form.day).getTime() / 1000
      this.$v1Api.modifyBirth({
        birthday: time
      }).then(res => {
        if (res && !res.err) {
          showAlertDialog(res.msg, null, 'success')
          this.userInfo.birthday = time
          this.closeModifyDialog()
        }
      })
    },
    /**
     * 修改用戶性別
     */
    modifyGender() {
      //驗證是否為尚未選擇
      if (!validSelectNum(this.form.gender)) {
        showAlertDialog(this.$t('errors.validSelectGender'))
        return
      }

      //更新資料
      this.$v1Api.modifyGender(this.form).then(res => {
        if (res && !res.err) {
          showAlertDialog(res.msg, null, 'success')
          this.userInfo.gender = this.form.gender
          this.closeModifyDialog()
        }
      })
    },
    /**
     * 修改用戶姓名
     */
    modifyUserName() {
      //檢查名字
      if (!validString(this.form.firstName)) {
        showAlertDialog(this.$t('errors.validFirstName'))
        return
      }

      //檢查姓
      if (!validString(this.form.lastName)) {
        showAlertDialog(this.$t('errors.validLastName'))
        return
      }

      //更新資料
      this.$v1Api.modifyUserName(this.form).then(res => {
        if (res && !res.err) {
          showAlertDialog(res.msg, null, 'success')
          this.userInfo.name.firstName = this.form.firstName
          this.userInfo.name.lastName = this.form.lastName
          this.closeModifyDialog()
        }
      })
    },
    /**
     * 修改用戶職業
     */
    modifyOccupation() {
      //驗證是否為尚未選擇
      if (!validSelectNum(this.form.occupation)) {
        showAlertDialog(this.$t('errors.validOccupation'))
        return
      }

      //更新資料
      this.$v1Api.modifyOccupation(this.form).then(res => {
        if (res && !res.err) {
          showAlertDialog(res.msg, null, 'success')
          this.userInfo.occupation = this.form.occupation
          this.closeModifyDialog()
        }
      })
    },
    /**
     * 顯示修改對話視窗
     * @param index Index
     */
    showModifyDialog(index) {
      //get enum
      const modifyEnum = this.ChangeEnum
      switch (index) {
        case modifyEnum.UserNameChange:
          this.form.firstName = this.userInfo.name.firstName
          this.form.lastName = this.userInfo.name.lastName
          break
        case modifyEnum.UserGenderChange:
          this.form.gender = this.userInfo.gender
          break
        case modifyEnum.UserBirthChange:
          const date = new Date(this.userInfo.birthday * 1000)
          this.form.birth = date
          this.form.year = date.getFullYear()
          this.form.month = date.getMonth()
          this.form.day = date.getDay()
          this.updateDaysInMonth()
          break
        case modifyEnum.UserCountryChange:
          this.form.country = this.userInfo.country
          break
        case modifyEnum.UserOccupationChange:
          this.form.occupation = this.userInfo.occupation
          break
      }

      this.showDialogIndex = index
      this.showDialog = true
    },
    //取得職業
    getOccupationStr() {
      let result = this.occupationList.find(s => this.userInfo.occupation === s.id)
      if (result !== undefined) {
        return result.value
      } else {
        return ''
      }
    },
    /**
     * 取得個人檔案資訊
     */
    getProfileInfo() {
      this.userInfo = JSON.parse(this.$getLocalStorage('userInfo'))
      if (!this.userInfo) {
        this.$v1Api.getProfileInfo({}).then(res => {
          this.userInfo = res.data
          this.$setLocalStorage('userInfo', JSON.stringify(this.userInfo))
        })
      }
    },
    /**
     * 取得國家清單
     */
    getCountry() {
      for (let index = 0; index < this.maxCountryLength; index++) {
        this.country.push(this.$t(`country.${index + 1}`))
      }
    },
    /**
     * 取得從1911到今年的年份
     */
    getFullYears() {
      this.years = getFullYears()
    },
    /**
     * 取得當前月份的天數
     */
    updateDaysInMonth() {
      //date填0取得那個月的總天數
      const date = new Date(this.form.year, this.form.month + 1, 0)
      //取得總天數
      this.daysInMonth = date.getDate()
      this.updateDates()
    },
    /**
     * 更新可選擇的日期
     */
    updateDates() {
      this.dates = []
      this.form.day = 1
      for (let date = 1; date <= this.daysInMonth; date++) {
        this.dates.push(date)
      }
    },
    /**
     * 取得所有職業
     */
    getAllOccupation() {
      this.$v1Api.getAllOccupation({}).then(res => {
        if (res && !res.err) {
          this.occupationList = res.data
          //重新排序
          this.occupationList = this.occupationList.sort((a, b) => a.id - b.id)
        }
      })
    },
    /**
     * 取得產品授權連結
     * @returns {string} 連結
     */
    getProductLicenseUrl() {
      this.token = getLocalStorage('token')
      //取得token
      this.token = process.env.NODE_ENV === 'development' ? this.token : window.atob(decodeURIComponent(this.token))
      return process.env.NODE_ENV === 'development' ? `https://dev-license.ipevo.com/signInRedirect/${this.token}` : `https://license.ipevo.com/signInRedirect/${this.token}`
    }
  },
  async created() {
    this.getProfileInfo()
    this.getAllOccupation()
    this.getCountry()
    this.getFullYears()
  }
}
</script>
