<template>
  <div class="sign_in_email">
    <div class="backStep" @click="$emit('backStep',0)">
      <img src="../../assets/images/backStep.svg" alt="">
      {{ $t('sign.signInBackToEmail') }}
    </div>
    <h2>{{ $t('sign.signIn') }}</h2>
    <div class="no_account">
      <p>{{ $t('sign.password.inputPassword') }}</p>
    </div>
    <div class="passwordFlex">
      <label for="password">{{ $t('sign.password.password') }}</label>
      <input v-model="password" :type="showPassword===false?'password':'text'" id="password" name="password"
             @keydown.enter="clickContinue"
             ref="password">
      <button @click="showPassword=!showPassword"
              :class="showPassword===false?'passwordSH':'passwordSH active'"></button>
    </div>
    <div class="sign_in_end forgotPassword">
      <router-link class="forgot_password" to="/resetPassword">
        {{ $t('sign.password.forgotPassword') }}
      </router-link>
      <a class="continue" @click="clickContinue">{{ $t('sign.continue') }}</a>
    </div>
  </div>
</template>
<script>
import {showAlertDialog, validPassword} from "ipevo_utils/dist"

export default {
  props: {
    email: String,
  },
  data() {
    return {
      //顯示密碼
      showPassword: false,
      //密碼
      password: null,
    }
  },
  methods: {
    /**
     * 點擊繼續
     */
    clickContinue() {
      //驗證密碼
      if (!validPassword(this.password)) {
        showAlertDialog(this.$t('errors.validPassword'))
      } else {
        this.singIn()
      }
    },
    /**
     * 登入
     */
    singIn() {
      this.$v1Api.login({
        username: this.email,
        password: this.password
      }).then(res => {
        //未認證時則直接轉跳
        if (res && !res.err && !res.data.canLogin) {
          this.$setLocalStorage('email', this.email)
        }
      })
    },
  }
}
</script>
