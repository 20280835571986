<template>
  <div class="sign_in_email">
    <h2>{{ $t('sign.signIn') }}</h2>
    <div class="no_account">
      <p>{{ $t('sign.email.haveAnAccount') }}</p>
      &nbsp;&nbsp;&nbsp;
      <router-link to="/createAccount">
        {{ $t('sign.createAnAccount') }}
      </router-link>
    </div>
    <label for="email_address">{{ $t('sign.emailAddress') }}</label>
    <input v-model="email" type="email" id="email_address" @keydown.enter="clickContinue" name="email_address"
           ref="email">
    <div class="sign_in_end single">
      <a class="continue" @click="clickContinue">{{ $t('sign.continue') }}</a>
    </div>
  </div>
</template>

<script>
import {showAlertDialog, validEmail} from "ipevo_utils/dist";

export default {
  name: 'SignInEmail',
  data() {
    return {
      email: null,
    }
  },
  methods: {
    clickContinue() {
      if (!this.email || this.email.length === 0 || !validEmail(this.email)) {
        this.$refs.email.focus()
        showAlertDialog(this.$t('errors.validEmail'))
      } else {
        this.$emit('sendEmail', 1, this.email)
      }
    },
  }
}
</script>
